import { ComponentProps } from "react";
import Link from "next/link";

export interface Badge {
  text: string;
  color?: "red" | "yellow" | "orange" | "green" | "blue" | "purple" | "gray";
  tooltip?: string;
}

interface ActionTileProps {
  title: string;
  description: string;
  icon: React.ComponentType<ComponentProps<"svg">>;
  onClick?: () => void;
  href?: string;
  badges?: Badge[];
}

export function ActionTile({
  title,
  description,
  icon: Icon,
  onClick,
  href,
  badges,
}: ActionTileProps) {
  const getBadgeColorClasses = (color: Badge["color"] = "blue") => {
    const colorMap = {
      red: "bg-red-100 text-red-800",
      yellow: "bg-yellow-100 text-yellow-800",
      orange: "bg-orange-100 text-orange-800",
      green: "bg-green-100 text-green-800",
      blue: "bg-blue-100 text-blue-800",
      purple: "bg-purple-100 text-purple-800",
      gray: "bg-gray-100 text-gray-800",
    };
    return colorMap[color];
  };

  const content = (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 hover:border-blue-500 transition-colors">
      <div className="flex items-center space-x-4">
        <div className="p-3 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
          <Icon className="w-6 h-6 text-blue-600" />
        </div>
        <div className="flex-grow">
          <h2 className="text-lg font-medium text-gray-900">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </div>

      {badges && badges.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-4">
          {badges.map((badge, index) => (
            <span
              key={index}
              className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getBadgeColorClasses(
                badge.color
              )}`}
              title={badge.tooltip}
            >
              {badge.text}
            </span>
          ))}
        </div>
      )}
    </div>
  );

  if (href) {
    return (
      <Link href={href} className="block w-full group">
        {content}
      </Link>
    );
  }

  return (
    <button onClick={onClick} className="block w-full group text-left">
      {content}
    </button>
  );
}
