"use client";

import {
  FolderIcon,
  ClipboardDocumentListIcon,
  BuildingStorefrontIcon,
  ClockIcon,
  ArchiveBoxXMarkIcon,
} from "@heroicons/react/24/outline";
import { ActionTile, Badge } from "./components/ActionTile";
import { Suspense } from "react";
import { useAuth } from "./context/auth-context";
import Link from "next/link";
import { useAppSelector } from "./lib/store/hooks";
import {
  selectCompanies,
  selectSelectedCompany,
} from "./lib/store/slices/appSelectors";
import { UserRoleLevels } from "./lib/interfaces";
import { selectPendingOrProgressTasksStatisticsForCurrentUser } from "./lib/store/slices/tasks/tasksSelector";

export default function DashboardPage() {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center min-h-screen">
          Loading...
        </div>
      }
    >
      <DashboardContent />
    </Suspense>
  );
}

function DashboardContent() {
  const { user, loading } = useAuth();
  const companies = useAppSelector(selectCompanies);
  const selectedCompany = useAppSelector(selectSelectedCompany);

  // Always call the hook, but provide a fallback empty string if companyId is undefined
  const taskStats = useAppSelector(
    selectPendingOrProgressTasksStatisticsForCurrentUser(
      selectedCompany?.companyId || "",
      user?.uid || ""
    )
  );

  // If no valid stats returned (likely because no company selected), use default values
  const safeTaskStats = taskStats || {
    total: 0,
    dueIn2Days: 0,
    dueIn1Day: 0,
    overdue: 0,
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        Loading...
      </div>
    );
  }

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">
            Please sign in to continue
          </h1>
          <Link
            href="/auth/login"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
          >
            Sign In
          </Link>
        </div>
      </div>
    );
  }

  const hasVerifiedCompanies = companies?.some((company) => company.isVerified);

  if (!companies || companies.length === 0 || !hasVerifiedCompanies) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <h1 className="text-xl text-gray-600">No verified companies found</h1>
      </div>
    );
  }

  const canManageSuppliers =
    selectedCompany?.userRole === UserRoleLevels.ADMIN ||
    selectedCompany?.userRole === UserRoleLevels.MANAGER;

  // Create badges for tasks
  const taskBadges: Badge[] = [];

  if (safeTaskStats.overdue > 0) {
    taskBadges.push({
      text: `${safeTaskStats.overdue} overdue`,
      color: "red",
      tooltip: "Tasks that are past their due date",
    });
  }

  if (safeTaskStats.dueIn1Day > 0) {
    taskBadges.push({
      text: `${safeTaskStats.dueIn1Day} due today`,
      color: "orange",
      tooltip: "Tasks due within 24 hours",
    });
  }

  if (safeTaskStats.dueIn2Days > 0) {
    taskBadges.push({
      text: `${safeTaskStats.dueIn2Days} due soon`,
      color: "yellow",
      tooltip: "Tasks due within 48 hours",
    });
  }

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-2xl font-semibold mb-6">Dashboard</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <ActionTile
          href="/projects"
          title="Active Projects"
          description="View and manage active projects"
          icon={FolderIcon}
        />

        <ActionTile
          href="/tasks"
          title="My tasks"
          description="See all my tasks"
          icon={ClipboardDocumentListIcon}
          badges={taskBadges}
        />

        {canManageSuppliers && (
          <ActionTile
            href="/suppliers-database"
            title="Suppliers"
            description="View and manage suppliers"
            icon={BuildingStorefrontIcon}
          />
        )}

        <ActionTile
          href="/time-management"
          title="Time Management"
          description="Timelines and Gantt charts"
          icon={ClockIcon}
        />

        <ActionTile
          href="/projects?isClosed=true"
          title="Closed Projects"
          description="View closed projects"
          icon={ArchiveBoxXMarkIcon}
        />
      </div>
    </div>
  );
}
