"use client";

import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../lib/firebase/firebase";
import { useDispatch } from "react-redux";
import { fetchDocument, simpleSetDocument } from "../lib/firebase/firebaseCRUD";
import { PATHS } from "../lib/firebase/constants";
import { UserInformation, UserRoleLevels } from "../lib/interfaces";
import { DateService } from "../lib/services/date.service";
import toast from "react-hot-toast";
import { clearAllCache } from "../lib/firebase/firebaseSubscriptions";

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signInWithEmail: (email: string, password: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signInWithMicrosoft: () => Promise<void>;
  signUpWithEmail: (
    email: string,
    password: string,
    companyId: string
  ) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Helper function to check if we're in a browser environment
const isBrowser = () => typeof window !== "undefined";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setLocalUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isBrowser()) {
      setLoading(false);
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("Auth state changed", user?.email);
      setLocalUser(user);
      // dispatch(setUser(user));

      if (user) {
        const token = await user.getIdToken();
        document.cookie = `__firebase_auth_token=${token}; path=/`;
      } else {
        document.cookie =
          "__firebase_auth_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [dispatch]);

  const createNewUserDocument = async (userInfo: UserInformation) => {
    await simpleSetDocument({
      path: PATHS.collections.users(),
      data: userInfo,
    });
  };

  const fetchUserDocument = async (userId: string) => {
    return await fetchDocument<UserInformation>(PATHS.documents.user(userId));
  };

  const signInWithEmail = async (email: string, password: string) => {
    if (!isBrowser()) return;
    const result = await signInWithEmailAndPassword(auth, email, password);
    const token = await result.user.getIdToken();
    document.cookie = `__firebase_auth_token=${token}; path=/`;
  };

  const signInWithGoogle = async () => {
    if (!isBrowser()) return;
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);

    if (!result.user.email) {
      toast.error("Error signing in with Google. Please contact support.");
      throw new Error("User email not found");
    }

    // Check if user exists in Firestore
    const userDoc = await fetchUserDocument(result.user.uid);
    if (!userDoc) {
      const userInfo: UserInformation = {
        id: result.user.uid,
        email: result.user.email,
        displayName: result.user.displayName,
        photoURL: result.user.photoURL,
        createdAt: DateService.now(),
        companies: [],
      };
      await createNewUserDocument(userInfo);
    }

    const token = await result.user.getIdToken();
    document.cookie = `__firebase_auth_token=${token}; path=/`;
  };

  const signInWithMicrosoft = async () => {
    if (!isBrowser()) return;
    const provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      prompt: "consent",
    });
    const result = await signInWithPopup(auth, provider);

    if (!result.user.email) {
      toast.error("Error signing in with Microsoft. Please contact support.");
      throw new Error("User email not found");
    }

    // Check if user exists in Firestore
    const userDoc = await fetchUserDocument(result.user.uid);
    if (!userDoc) {
      // Create new user document
      const userInfo: UserInformation = {
        id: result.user.uid,
        email: result.user.email,
        displayName: result.user.displayName,
        photoURL: result.user.photoURL,
        createdAt: DateService.now(),
        companies: [],
      };
      await createNewUserDocument(userInfo);
    }

    const token = await result.user.getIdToken();
    document.cookie = `__firebase_auth_token=${token}; path=/`;
  };

  const signUpWithEmail = async (
    email: string,
    password: string,
    companyId: string
  ) => {
    if (!isBrowser()) return;
    const result = await createUserWithEmailAndPassword(auth, email, password);

    if (!result.user.email) {
      toast.error("Error signing up with email. Please contact support.");
      throw new Error("User email not found");
    }

    const userInfo: UserInformation = {
      id: result.user.uid,
      email: result.user.email,
      displayName: result.user.displayName,
      photoURL: result.user.photoURL,
      companies: [
        {
          companyId,
          isVerified: false,
          userRole: UserRoleLevels.NOT_AUTHORIZED,
          companyName: "",
        },
      ],
    };

    await createNewUserDocument(userInfo);

    // Create new user document
    //TODO: here create a new user request

    const token = await result.user.getIdToken();
    document.cookie = `__firebase_auth_token=${token}; path=/`;
  };

  const logout = async () => {
    const result = await clearAllCache();
    if (!result) {
      toast.error(
        "Error clearing cache. Cannot logout. Please contact support."
      );
      return;
    }
    if (!isBrowser()) return;
    await signOut(auth);
    document.cookie =
      "__firebase_auth_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        signInWithEmail,
        signInWithGoogle,
        signInWithMicrosoft,
        signUpWithEmail,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
